<template>
  <div>
    <div v-if="backBut" style="display:flex;justify-content:space-between;margin-bottom:5px">
      <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items" style="padding-top:0px;background: none;border: none;" />
      <Button @click="back($router.go(-1))" label="返回" class=" p-button-sm p-button-outlined p-button-secondary" style="padding:0px;width:65px;border:1px solid rgba(217, 217, 217, 1);margin-bottom:10px;" />
    </div>
    <div class="card p-d-flex p-justify-center">
      <div class="p-d-flex p-flex-wrap" style="width: 80%">
        <h3 v-if="title" class="p-col-12 p-text-center">项目规划信息</h3>
        <div id="littleMap" class="p-col-12" style="margin-bottom: 30px;display: flex;width:50vw">
          <label style="width:120px;">项目圈地</label>
          <div id="littleShowMap" class="p-col" style="height: 24vw;padding: 0">
            <show-map ref="showMap" :update-but="false"></show-map>
          </div>
        </div>
        <div class="p-col-6">
          <div>
            <label style="width:120px;">项目名称</label>
            <span>{{ from.name||'-' }}</span>
          </div>
          <div>
            <label style="width:120px;">项目地点</label>
            <span>{{ from.province + from.city + from.district ||'-'}}</span>
          </div>
          <div>
            <label style="width:120px;">详细地址</label>
            <span>{{ from.location||'-' }}</span>
          </div>
          <div>
            <label style="width:120px;">创建用户姓名</label>
            <span>{{ from.user ? from.user.name : '-' }}</span>
          </div>
          <div>
            <label style="width:120px;">创建用户联系方式</label>
            <span>{{ from.user ? from.user.telephone : '-' }}</span>
          </div>

          <div>
            <label style="width:120px;">项目面积</label>
            <span>{{ from.area + from.areaUnit||'-' }}</span>
          </div>

          <div>
            <label style="width:120px;">整地条件</label>
            <span>{{ from.cond||'-' }}</span>
          </div>
          <div>
            <label style="width:120px;">灌溉条件</label>
            <span>{{ from.irrigation||'-' }}</span>
          </div>

          <div>
            <label style="width:120px;">用于生态治理</label>
            <span>{{ from.isEcoGovernance == 0 ? '是' : from.isEcoGovernance == 1 ? '否' : '-' }}</span>
          </div>
          <div>
            <label style="width:120px;">菌草成熟后再利用</label>
            <span>{{ from.isReuse == 0 ? '是' : '否' }}</span>
          </div>
          <div>
            <label style="width:120px;">土壤属性</label>
            <span>{{ from.soil||'-' }}</span>
          </div>
          <div v-if="from.soil==='盐碱地'">
            <label style="width:120px;">PH值</label>
            <span>{{ from.soilPh ||'-'}}</span>
          </div>

        </div>
        <div class="p-col-6 p-p-0">

          <div>
            <label style="width:120px;">年平均气温</label>
            <span>{{ from.weatherData ? from.weatherData.t : '-' }}°C</span>
          </div>
          <div>
            <label style="width:120px;">年平均最高气温</label>
            <span>{{ from.weatherData ? from.weatherData.tmax : '-' }}°C</span>
          </div>
          <div>
            <label style="width:120px;">年平均最低气温</label>
            <span>{{ from.weatherData ? from.weatherData.tmin : '-' }}°C</span>
          </div>
          <div>
            <label style="width:120px;">年度龙卷风天数</label>
            <span>{{ from.weatherData ? from.weatherData.tn : '-' }}天</span>
          </div>
          <div>
            <label style="width:120px;">年度冰雹天数</label>
            <span>{{ from.weatherData ? from.weatherData.gr : '-' }}天</span>
          </div>

          <div>
            <label style="width:120px;">年度降雨量</label>
            <span>{{ from.weatherData ? from.weatherData.pp : '- ' }}mm</span>
          </div>
          <div>
            <label style="width:120px;">年度下雨天数</label>
            <span>{{ from.weatherData ? from.weatherData.ra : '-' }}天</span>
          </div>
          <div>
            <label style="width:120px;">年度下雪天数</label>
            <span>{{ from.weatherData ? from.weatherData.sn : '-' }}天</span>
          </div>
          <div>
            <label style="width:120px;">年度雷阵雨天数</label>
            <span>{{ from.weatherData ? from.weatherData.ts : '-' }}天</span>
          </div>
          <div>
            <label style="width:120px;">年度起雾天数</label>
            <span>{{ from.weatherData ? from.weatherData.fg : '-' }}天</span>
          </div>

          <div v-if="from.soil==='盐碱地'">
            <label style="width:120px;">含盐量</label>
            <span>{{ from.soilSalt ||'-'}}</span>
          </div>
          <div v-if="from.soil==='盐碱地'">
            <label style="width:120px;">电导率（EC）</label>
            <span>{{ (from.soilConductivity + from.soilConductivityUnit)||'-' }}</span>
          </div>

        </div>
        <div class="p-col-12" style="margin-bottom: 30px">
          <label style="width:120px;">项目坐标</label>
          <span>{{ from.positionLat==null?null:from.positionLat}} </span>,
          <span> {{from.positionLon==null?null:from.positionLon}}</span>
        </div>
        <div class="p-col-12" style="margin-bottom: 30px">
          <label style="width:120px;">生态治理目标</label>
          <span>{{ from.ecoGovernanceAim ||'-'}}</span>
        </div>
        <div class="p-col-12" style="margin-bottom: 30px">
          <label style="width:170px;margin-left: -50px;">菌草成熟收货后的加工目标</label>
          <span>{{ from.procAim ||'-'}}</span>
        </div>
        <!-- <div class="p-col-12" style="margin-bottom: 30px;text-align: center">
          <Button v-if="backBut" @click="$router.push('/plan')">返回</Button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import ShowMap from "@/views/modules/plan/showMap";

export default {
  name: "showProject",
  components: { ShowMap },
  data() {
    return {
      breadcrumb: {
        home: { label: "种植规划", to: "/plan" },
        items: [
          // { label: "列表", to: "#" },
          { label: "项目规划信息", to: "#" },
        ],
      },
      from: {
        id: null,
        name: null, //项目名称
        country: null, //国家
        province: null, //省/洲/自治区
        city: null, //市
        district: null, //区/县
        location: null, //具体地址
        area: null, //项目面积
        areaUnit: null, //项目面积单位
        positionLon: null, //项目坐标，经度
        positionLat: null, //项目坐标，纬度
        cond: null, //整地条件
        irrigation: null, //灌溉条件
        irrigationPrice: null, //每亩单价
        irrigationPriceUnit: null, //每亩单价的单位
        soil: null, //土壤属性
        soilPh: null, //土壤ph值
        soilSalt: null, //含盐量
        soilConductivity: null, //电导率
        soilConductivityUnit: null, //电导率单位
        isEcoGovernance: null, //用于生态治理，是：0，否1
        ecoGovernanceAim: null, //生态治理目标
        isReuse: null, //菌草成熟后再利用，再利用0，非再利用1
        procAim: null, //菌草成熟收获后的加工目标
        expertId: null, //分配的专家id
        areaMap: null, //圈地经纬度表,
        isDraft: 0,
        weatherData: null, //天气
      },
    };
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    backBut: {
      type: Boolean,
      default: true,
    },
    title: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.from.id = this.$route.query.id;
      this.getInfo();
    }
    // console.log("id:" + this.id);
    if (this.id) {
      document.getElementById("littleMap").style.width = 35 + "vw";
      document.getElementById("littleShowMap").style.height = 18 + "vw";
      this.from.id = this.id;
      this.getInfo();
    }
  },
  methods: {
    getInfo() {
      this.$http("plan/getById", "get", { id: this.from.id }, (res) => {
        // console.log(res.data);
        this.from = res.data;
        let info = {
          area: res.data.area,
          path: JSON.parse(res.data.areaMap),
          center: [res.data.positionLon, res.data.positionLat],
        };
        this.$nextTick(() => {
          this.$refs.showMap.update(info);
        });
      });
    },
  },
};
</script>

<style scoped>
label {
  font-weight: 600;
  color: #999999;
  font-size: 14px;
  margin-right: 1rem;
  text-align: right;
  display: inline-block;
  white-space: nowrap;
}

.p-col-6 > div {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
}
</style>
